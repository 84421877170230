<template>
    <div>
        <v-dialog v-model="dialog" max-width="600px" @click:outside="close()">
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="primary" v-bind="attrs" v-on="on">
                    {{ $t('users.add_user') }}
                </v-btn>
            </template>

            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ $t('users.add_user') }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field :label="$t('users.first_name')" :rules="[v => !!v]" required
                                        v-model="name"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field :label="$t('users.last_name')" :rules="[v => !!v]" required
                                        v-model="surname">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field :label="$t('users.email')" :rules="emailRules" required
                                        v-model="email"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field :label="$t('users.password')" :rules="passRules" type="password"
                                        required v-model="password">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-select item-text="text" item-value="value" :items="types"
                                        :label="$t('users.type')" :rules="[v => !!v]" required v-model="initialType">
                                    </v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-alert :value="isError" color="error" dark border="top"
                                        transition="scale-transition">
                                        {{ error_text }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="close()">
                            {{ $t('buttons.cancel') }}
                        </v-btn>
                        <v-btn class="primary" :disabled="!valid" @click="validate">
                            {{ $t('buttons.save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data: function () {
        return {
            dialog: false,
            valid: true,
            error_text: "",
            isError: false,
            name: "",
            surname: "",
            email: "",
            active: 1,
            emailRules: [
                v => !!v,
                v => /.+@.+\..+/.test(v),
            ],
            password: "",
            passRules: [
                v => (v && v.length >= 8) || this.$t('users.password_error'),
            ],

            types: [
                {
                    text: "naročnik",
                    value: "0"
                },
                {
                    text: "manager",
                    value: "1"
                },
                {
                    text: "administrator",
                    value: "2"
                },
            ],
            initialType: "0"
        }
    },

    computed: {
    },

    methods: {
        ...mapActions(["addUser"]),

        validate() {
            let isValid = this.$refs.form.validate();
            if (isValid) this.send();
        },

        close() {
            this.dialog = false;
            this.$emit('close');
        },

        send() {
            this.addUser({
                data: {
                    name: this.name,
                    surname: this.surname,
                    email: this.email,
                    type: this.initialType,
                    password: this.password,
                    active: this.active
                },
                callback: (e) => {
                    switch (e) {
                        case 'success':
                            this.close();
                            break;
                        case 'duplicate':
                            this.isError = true;
                            this.error_text = this.$t('users.email_exist')
                            break;
                        case 'error':
                            this.isError = true;
                            this.error_text = this.$t('users.edit_error')
                            break;
                    }
                }
            });
        },
    },
}
</script>