<template>
    <div>
        <v-tabs v-model="tab" background-color="transparent" color="primary" fixed-tabs center-active>
            <v-tab v-for="(tab_name, i) in tabItems" :key="i" :disabled="i > 0">
                {{ tab_name }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <UsersList />
            <Organisation />
            <OrgHierarchy />
            <TargetGroups />
            <ActivityTypes />
            <Staff />
            <Participants />
        </v-tabs-items>
    </div>
</template>


<script>
import { mapActions } from "vuex";
import UsersList from "../components/management/UsersList.vue";
import Organisation from "../components/management/Organisation.vue";
import OrgHierarchy from "../components/management/OrgHierarchy.vue";
import TargetGroups from "../components/management/TargetGroups.vue";
import ActivityTypes from "../components/management/ActivityTypes.vue";
import Staff from "../components/management/Staff.vue";
import Participants from "../components/management/Participants.vue";


export default {
    name: "Management",
    components: {
        UsersList,
        Organisation,
        OrgHierarchy,
        TargetGroups,
        ActivityTypes,
        Staff,
        Participants
    },
    data: function () {
        return {
            tab: null
        }
    },
    computed: {

        tabItems() {
            return [
                this.$t('management.users'),
                this.$t('management.organisation_hierarchy'),
                this.$t('management.organisation'),
                this.$t('management.target_groups'),
                this.$t('management.activity_types'),
                this.$t('management.staff'),
                this.$t('management.participants') + ' / ' + this.$t('management.volunteers')
            ]
        },

        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },
        getUserID() {
            return this.$store.getters.getUser.id;
        },
    },
    methods: {
        ...mapActions([
        ]),
    },

    created() {
    },
};
</script>


<style lang="scss" scoped>
@import "../scss/style.scss";
</style>
