<template>
    <div>
        <v-dialog v-model="dialog" max-width="600px" @click:outside="close()">
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="accent" v-bind="attrs" v-on="on" :disabled="users.length == 0">
                    {{ $tc('users.delete_user', 2) }}
                </v-btn>
            </template>

            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ this.$tc('users.delete_user', 2) }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <p>{{ this.$tc('users.delete_user_content', 2) }}</p>
                                <ul style="list-style-type:none;">
                                    <li v-for="user_id in users" :key="user_id">
                                        <v-checkbox class="mr-n10 mt-0" hide-details dense 
                                            input-value="true"
                                            :label="user_data[user_id].name + ' ' + user_data[user_id].surname" 
                                            @change="setChecked($event, user_id); none_checked = disable_send()"/>
                                        <!-- <input type="checkbox"
                                            @change="setChecked(user_id, $event.target); none_checked = disable_send()"
                                            checked>
                                        {{user_data[user_id].name}} {{user_data[user_id].surname}} -->
                                    </li>
                                </ul>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="close()">
                        {{ $t('buttons.cancel') }}
                    </v-btn>
                    <v-btn class="primary" @click="send()" :disabled="none_checked" :class="{disabled: none_checked}">
                        {{ $t('buttons.confirm') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
import { mapActions } from "vuex";

export default {
    name: "DeleteUsers",
    data: function () {
        return {
            selected_users: {},
            none_checked: false,
            dialog: false,
        };
    },

    props: {
        users: {
            required: true
        },
    },

    computed: {
        user_data() {
            return this.$store.getters.users_list_data
                ? this.$store.getters.users_list_data
                : {};
        },
    },
    methods: {
        ...mapActions(["deleteUser"]),

        close() {
            this.dialog = false;
            this.$emit('close');
        },

        send() {
            //create final array of checked users to delete
            if (Array.isArray(this.users)) {
                let selected_users_id = [];
                for (let user_id in this.selected_users) {
                    if (this.selected_users[user_id])
                        selected_users_id.push(user_id);
                }
                this.deleteUser({ id: selected_users_id });
            }
            else {
                this.deleteUser({ id: this.users });
            }

            this.close();
        },

        setChecked(e, user_id) {
            this.selected_users[user_id] = e;
        },

        //check if we have to disable send button (if user unchecked all)
        disable_send() {
            if (Array.isArray(this.users)) {
                for (let user_id in this.selected_users) {
                    if (this.selected_users[user_id])
                        return false;
                }
                return true;
            }
            return false;
        },

        //create array with id:boolean pairs for selections
        create_selected_users() {
            this.selected_users = {};
            //at start, all are always true
            for (let user_id of this.users) {
                this.selected_users[user_id] = true;
            }
        },
    },

    watch: {
        users(newarr, oldarr) {
            this.create_selected_users();
        }
    },

    created() {
        if (Array.isArray(this.users))
            this.create_selected_users();
    }
};
</script>


<style lang="scss" scoped>
@import "../../../scss/style.scss";
</style>
