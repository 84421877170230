export const showModalWorking = (state, payload) => {
    state.modalWorkingVisible = true;
};

export const hideModalWorking = (state, payload) => {
	state.modalWorkingVisible = false;
};

// Set token
export const setToken = (state, payload) => {
    state.token = payload;
};

// Login user
export const setAuthData = (state, payload) => {
    
    state.user = payload.data.user;

    // Prolong expiration date
    let expirationDate = new Date(new Date().getTime() + state.expirationTime).getTime();
    localStorage.setItem('expirationDate', expirationDate);

    state.isAuthenticated = true;
};

// Logout user
export const clearAuthData = (state) => {
    
    state.user = {};

    state.isAuthenticated = false;
    state.token = '';

    // Clear local storage
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('expirationDate');
};


// Set Version
export const prepareVersion = (state, payload) => {
    state.version = payload.data.value;
};