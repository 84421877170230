import axios from 'axios'
import * as general_mutations from '../mutations';
import * as general_state from '../store';

const state = {
    // User data (name, surname, email): user management for data table
	users_list_data: {},
	
	// All users
	added_users: {},

	// Deleted users
	deleted_users: {},
};


const getters = {
	// Return list of users 
	users_list_data: store => {
		return state.users_list_data;
	},
	
	// Return list of new users 
	new_users_list_data: store => {
		return state.added_users;
	},

	// Return list of deleted users 
	deleted_users_list_data: store => {
		return state.deleted_users;
	},
};


const mutations = {

    // Prepare list of all sections for user
	setUserList: (state, payload) => {
		state.users_list_data = payload;
	},

	// Prepare list of all added users
	addUser: (state, payload) => {
		state.users_list_data[payload.id] = payload;
		// Force refresh - VUE SET...?
		var temp = state.users_list_data;
		state.users_list_data = {};
		state.users_list_data = temp;
	},

	// Remove subsection from array
	deleteUser: (state, payload) => {
		let users_id = [];

		//create array of one element if single delete
		if(!Array.isArray(payload))
			users_id.push(payload);
		else
			users_id = payload;

		//delete multiple users
		for(let id of users_id){
			delete state.users_list_data[id];
		}

		// Force refresh - VUE SET...?
		var temp = state.users_list_data;
		state.users_list_data = {};
		state.users_list_data = temp;
	},

	// Prepare list of all added users
	editUser: (state, payload) => {
		//If we are editing user in user profile (self)
		if(payload.id == general_state.state.user.id){
			general_state.state.user.name = payload.name;
			general_state.state.user.surname = payload.surname;
			general_state.state.user.email = payload.email;
		}

		state.users_list_data[payload.id] = payload;
		// Force refresh - VUE SET...?
		var temp = state.users_list_data;
		state.users_list_data = {};
		state.users_list_data = temp;
	},
};


const actions = {
	// Get users data for user management (userList): data table
	getUserList: ({commit}, payload) => {
		axios.get('?module=user&action=get_user_list')
		.then(
			res => {
				if(!res.data){				
					console.error("module=user&action=get_user_list => " + res.data.error);
				}
				else{
					commit('setUserList', res.data);
				}
				payload.callback();
			}
		)
		.catch(error => console.log(error))		
	},
    
	// Add New users data for user management (userList): data table
	addUser: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=user&action=add_user', payload.data )
		.then(
			res => {
				if(!res.data.success){				
					var error = res.data.error ? res.data.error : "error";
					general_mutations.hideModalWorking(general_state.state);

					if(error.includes("Duplicate") && error.includes("email"))
						error = "duplicate"
					payload.callback(error);
				}
				else{
                    payload.data.id = res.data.user_id;
					commit('addUser', payload.data);
					general_mutations.hideModalWorking(general_state.state);

					payload.callback('success');
				}
			}
		)
		.catch(error => console.log(error))		
	},
	
	// Delete user (userList): data table
	deleteUser: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=user&action=delete_user&user_id='+ payload.id )
		.then(
			res => {
				if(!res.data.success){				
					general_mutations.hideModalWorking(general_state.state);
					var error = res.data.error ? res.data.error : "Error while deleting user/s";
					console.error(error);
				}
				else{
					commit('deleteUser', payload.id);
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => console.log(error))
	},

	// Edit  users  (userList): data table
	editUser: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=user&action=edit_user&user_id='+ payload.data.id, payload.data)
		.then(
			res => {
				if(!res.data.success){				
					general_mutations.hideModalWorking(general_state.state);
					var error = res.data.error ? res.data.error : "error";
					if(error.includes("Duplicate") && error.includes("email"))
						error = "duplicate"
					payload.callback(error);
				}
				else{
					commit('editUser', payload.data);
					general_mutations.hideModalWorking(general_state.state);

					payload.callback('success');
                }
            }
        )
        .catch(error => console.log(error))		
    },
	
};


export default{
	state,
	getters,
	actions,
	mutations	
}