import Vue from 'vue';
import Vuex from 'vuex';

import users from './modules/users';

import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

Vue.use(Vuex);

export const state = {
    
    // Data for popup modal
    modalWorkingVisible: false,
    
    // App version
    version: {},

    // Loged in user data
    token: '',
    isAuthenticated: false,
    user: {},
    // Time in ms when login is about to expire
    expirationTime: 4 * 60 * 60 * 1000,

};

export const store = new Vuex.Store({
    state,
	getters,
    mutations,
    actions,
    modules: {
        users,
    }
});