<template>
    <div>
        <v-dialog v-model="dialog" max-width="600px" @click:outside="close()">
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="secondary" v-bind="attrs" v-on="on" :disabled="activities.length == 0">
                    {{ $t('activities.open_checkin') }}
                </v-btn>
            </template>

                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ $t('activities.open_checkin') }}</span>
                    </v-card-title>
                    <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <p>{{ this.$tc('activities.open_checkin_message') }}</p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="close()">
                        {{ $t('buttons.cancel') }}
                    </v-btn>
                    <v-btn class="primary" @click="close()">
                        {{ $t('buttons.confirm') }}
                    </v-btn>
                </v-card-actions>
                </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data: function () {
        return {
            selected_activities: {},
            none_checked: false,
            dialog: false,
        }
    },

    props: {
        activities: {
            required: true
        },
    },

    computed: {
    },

    methods: {
        ...mapActions([]),

        close() {
            this.dialog = false;
            this.$emit('close');
        },

        send() {
            this.dialog = false;
            this.$emit('close');
        },
    },
}
</script>