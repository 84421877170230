<template>
    <v-app>
        <v-navigation-drawer v-if="currentRouteName !== 'login'" v-model="drawer" app clipped
            :style="{ top: $vuetify.application.top + 'px', zIndex: 4 }">
            <v-list-item-group nav active-class="primary--text text--primary-4">

                <LocalizedListItem class="py-4" two-line :to="'/my-account'">
                    <v-list-item-avatar>
                        <v-avatar color="secondary white--text" size="40">{{ user_initials }}</v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{ user_data.name }} {{ user_data.surname }}</v-list-item-title>
                        <v-list-item-subtitle>{{ user_data.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </LocalizedListItem>

                <v-divider></v-divider>

                <LocalizedListItem :to="'/'">
                    <v-list-item-icon>
                        <v-icon>mdi-account-group-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('menu.groups') }}</v-list-item-title>
                </LocalizedListItem>

                <LocalizedListItem :to="'/activities'">
                    <v-list-item-icon>
                        <v-icon>mdi-dots-hexagon</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('menu.activities') }}</v-list-item-title>
                </LocalizedListItem>

                <LocalizedListItem :to="'/reports'">
                    <v-list-item-icon>
                        <v-icon>mdi-chart-timeline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('menu.reports') }}</v-list-item-title>
                </LocalizedListItem>

                <LocalizedListItem :to="'/documents'">
                    <v-list-item-icon>
                        <v-icon>mdi-folder-multiple-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('menu.documents') }}</v-list-item-title>
                </LocalizedListItem>

                <LocalizedListItem :to="'/surveys'">
                    <v-list-item-icon>
                        <v-icon>mdi-format-list-checks</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('menu.surveys') }}</v-list-item-title>
                </LocalizedListItem>

                <LocalizedListItem :to="'/messages'">
                    <v-list-item-icon>
                        <v-icon>mdi-message-text-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('menu.messages') }}</v-list-item-title>
                </LocalizedListItem>

                <LocalizedListItem :to="'/management'">
                    <v-list-item-icon>
                        <v-icon>mdi-office-building-cog-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('menu.management') }}</v-list-item-title>
                </LocalizedListItem>

            </v-list-item-group>
        </v-navigation-drawer>

        <v-app-bar color="primary" dark app clipped-left>
            <v-app-bar-nav-icon @click="drawer = !drawer" v-if="currentRouteName !== 'login'"></v-app-bar-nav-icon>

            <div class="ml-5 d-flex align-center">
                <div id="logo" class="logo">
                    MAMALog
                </div>
            </div>

            <v-spacer></v-spacer>

            <LocaleSwitcher />

            <!-- <div class="d-flex align-center">
                <div class="app_name logo">
                    <LocalizedLink :to="'/'">
                        <img src="./assets/img/Eutopia logo blanc.png"> 
                    </LocalizedLink>
                </div>
            </div> -->
        </v-app-bar>

        <div class="wrapper">
            <v-main>
                <router-view></router-view>
            </v-main>

            <v-footer app inset absolute>
                <TheFooter />
            </v-footer>

            <ul-modal-working></ul-modal-working>
        </div>
    </v-app>
</template>

<script>
import { mapActions } from "vuex";
import TheFooter from "./components/TheFooter.vue";
import ModalWorking from "./components/modal/ModalWorking.vue";
import LocaleSwitcher from './components/LocaleSwitcher.vue';
import LocalizedLink from "@/components/LocalizedLink"
import LocalizedListItem from "@/components/LocalizedListItem"

export default {
    name: "App",

    components: {
        TheFooter,
        "ul-modal-working": ModalWorking,
        LocaleSwitcher,
        LocalizedLink,
        LocalizedListItem
    },

    computed: {
        currentRouteName() {
            return this.$route.name;
        },

        organizations() {
            return this.$store.getters.organizations;
        },

        user_data() {
            return this.$store.getters.getUser;
        },

        user_initials() {
            return (this.$store.getters.getUser.name[0] + this.$store.getters.getUser.surname[0]).toUpperCase();
        }
    },

    data: () => ({
        isLoading: true,
        drawer: true,
    }),

    methods: {
    },

    mounted() {
    },

    created() {
    },
};
</script>

<style lang="scss" scoped>
@import "src/scss/style.scss";
</style>
