<template>
    <v-tab-item>
        <div class="main_holder users">
            <div class="content_holder">
                <div class="content">
                    <div class="container">

                        <!-- <h2>{{ $t('users.user_list') }}</h2> -->

                        <div>
                            <div class="users_toolbar">

                                <!-- User Search bar -->
                                <div class="search_bar">
                                    <v-text-field dense :label="$t('other.search')" append-icon="mdi-magnify"
                                        v-model="search"></v-text-field>
                                </div>

                                <!-- Add user button -->
                                <div class="ml-3">
                                    <AddUser @close="forceRerenderDialog()" :key="`${componentKey}-add`" />
                                </div>

                                <!-- Delete user button -->
                                <div class="ml-3">
                                    <DeleteUsers :users="userIds" @close="forceRerenderDialog()"
                                        :key="`${componentKey}-deletem`" />
                                </div>
                            </div>


                            <v-data-table :headers="headers" :items="filteredRow" sort-by="name" class="elevation-1"
                                :loading="loading" :loading-text="this.$t('other.loading_long')">
                                <template v-slot:item.checkbox="{ item }">
                                    <v-layout justify-center>
                                        <v-checkbox class="mt-0" hide-details dense v-model="userIds"
                                            :value="item.id" />
                                    </v-layout>
                                </template>
                                <template v-slot:item.type="{ item }">
                                    {{ types[item.type].text }}
                                </template>
                                <template v-slot:item.active="{ item }">
                                    {{ activity[item.active].text }}
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-layout justify-center>
                                        <EditUser :user_id="item.id" @close="forceRerenderDialog()"
                                            :key="`${componentKey}-${item.id}`"
                                            v-if="getUserType == 2 || item.type == 0 || item.id == getUserID" />

                                        <DeleteUser :user_id="item.id" v-if="getUserType == 2 || item.type == 0" />
                                    </v-layout>
                                </template>
                            </v-data-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-tab-item>
</template>


<script>
import { mapActions } from "vuex";
import AddUser from "../modal/users/AddUser.vue";
import DeleteUsers from "../modal/users/DeleteUsers.vue";
import DeleteUser from "../modal/users/DeleteUser.vue";
import EditUser from "../modal/users/EditUser.vue";


export default {
    name: "UsersList",
    components: {
        AddUser,
        EditUser,
        DeleteUsers,
        DeleteUser
    },
    data: function () {
        return {
            loading: true,
            search: "",
            selectAll: false,
            userIds: [],
            componentKey: 0,

            types: [
                {
                    text: "naročnik",
                    value: "0"
                },
                {
                    text: "manager",
                    value: "1"
                },
                {
                    text: "administrator",
                    value: "2"
                },
            ],
            activity: [
                {
                    text: "neaktiven",
                    value: "0"
                },
                {
                    text: "aktiven",
                    value: "1"
                }
            ],
        };
    },
    computed: {

        user_data() {
            this.userIds = [];

            let data = this.$store.getters.users_list_data
                ? this.$store.getters.users_list_data
                : {};

            let values = Object.values(data);
            return values;
        },

        headers() {
            return [
                { text: "", value: 'checkbox', sortable: false, align: 'center', width: '65px' },
                { text: this.$t('users.first_name'), value: 'name' },
                { text: this.$t('users.last_name'), value: 'surname' },
                { text: this.$t('users.email'), value: 'email' },
                { text: this.$t('users.type'), value: 'type' },
                { text: this.$t('users.activity'), value: 'active' },
                { text: this.$t('buttons.edit'), value: 'actions', sortable: false, align: 'center' },
            ];
        },

        filteredRow: function () {
            return this.user_data.filter((user) => {
                //Dont show admins to manager
                if (this.getUserType < 2 && user.type > 1) return false;

                for (var key in user) {
                    if (String(user[key]).indexOf(this.search) !== -1 && this.getUserID != user.id) {
                        return true;
                    }
                }
                return false;
            });
        },

        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },
        getUserID() {
            return this.$store.getters.getUser.id;
        },
    },
    methods: {
        ...mapActions([
            "getUserList",
            "addUser",
            "deleteUser",
        ]),

        forceRerenderDialog() {
            this.componentKey += 1;
        },

        select() {
            this.userIds = [];
            if (!this.selectAll) {
                for (let i in this.user_data) {
                    this.userIds.push(this.user_data[i].id);
                }
            }
        },
    },

    created() {
        if (this.getUserType == 2) {
            this.getUserList({ callback: () => { this.loading = false } });
        }
    },
};
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";
</style>
