<template>
    <v-tab-item>
        <div class="main_holder home">
            <div class="content_holder">
                <div class="content">
                    <div class="container">
                        <p>{{ $t('main.home_title', {name: 'Uros'}) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </v-tab-item>
</template>


<script>
import { mapActions } from "vuex";

export default {
    name: "Participants",
    components: {
    },
    data: function () {
        return {
        };
    },
    computed: {

    },
    methods: {
        ...mapActions([

        ]),

    },
    created() {

    },
};
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";
</style>
