import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#3f51b5',
                secondary: '#607d8b',
                accent: '#9c27b0',
                error: '#e91e63',
                warning: '#ff5722',
                //info: '#00bcd4',
                info: '#3f51b5',

                success: '#4caf50'
            },
        },
    },
});
