<template>
    <v-tab-item>
        <div class="reports_table_toolbar mb-1">
            <!-- User Search bar -->
            <div class="search_bar">
                <v-text-field hide-details append-icon="mdi-magnify" dense :label="$t('other.search')" v-model="search">
                </v-text-field>
            </div>
        </div>

        <v-data-table :headers="headers" :search="search" :items="activities" sort-by="date"
            :footer-props="table_footer" :loading-text="this.$t('other.loading_long')">
        </v-data-table>
    </v-tab-item>
</template>


<script>
import { mapActions } from "vuex";

export default {
    name: "ParticipantsTab",
    components: {
    },
    data: function () {
        return {
            loading: true,
            search: "",
            activities: [
                {
                    "name": "Kolarič Peregrine",
                    "organisation": "CONA Šmartno",
                    "birth_year": 2009,
                    "gender": "Female",
                    "assisting_activities": 0,
                    "producing_activities": 0,
                    "total": 15
                },
                {
                    "name": "Lavrič Sambrid",
                    "organisation": "CONA Šmartno",
                    "birth_year": 2008,
                    "gender": "Male",
                    "assisting_activities": 0,
                    "producing_activities": 0,
                    "total": 10
                },
                {
                    "name": "Marković Klavdija",
                    "organisation": "CONA Šmartno",
                    "birth_year": 2008,
                    "gender": "Male",
                    "assisting_activities": 1,
                    "producing_activities": 0,
                    "total": 13
                },
                {
                    "name": "Primožič Jan",
                    "organisation": "CONA Šmartno",
                    "birth_year": 2007,
                    "gender": "Male",
                    "assisting_activities": 0,
                    "producing_activities": 0,
                    "total": 3
                },
                {
                    "name": "Kolenc Tilen",
                    "organisation": "CONA Šmartno",
                    "birth_year": 2009,
                    "gender": "Male",
                    "assisting_activities": 0,
                    "producing_activities": 0,
                    "total": 3
                },
                {
                    "name": "Bogataj Sergej",
                    "organisation": "CONA Šmartno",
                    "birth_year": 2009,
                    "gender": "Male",
                    "assisting_activities": 0,
                    "producing_activities": 0,
                    "total": 4
                },
                {
                    "name": "Kobal Alexander",
                    "organisation": "CONA Šmartno",
                    "birth_year": 2012,
                    "gender": "Male",
                    "assisting_activities": 5,
                    "producing_activities": 0,
                    "total": 73
                },
                {
                    "name": "Jovanović Katarina",
                    "organisation": "CONA Šmartno",
                    "birth_year": 2007,
                    "gender": "Female",
                    "assisting_activities": 41,
                    "producing_activities": 0,
                    "total": 263
                },
                {
                    "name": "Kocjančič Renata",
                    "organisation": "CONA Šmartno",
                    "birth_year": 2010,
                    "gender": "Female",
                    "assisting_activities": 38,
                    "producing_activities": 0,
                    "total": 289
                },
                {
                    "name": "Hodžić Stefan",
                    "organisation": "CONA Šmartno",
                    "birth_year": 2009,
                    "gender": "Male",
                    "assisting_activities": 7,
                    "producing_activities": 0,
                    "total": 111
                }
            ],
        };
    },
    computed: {
        headers() {
            return [
                { text: this.$t('users.first_name'), value: 'name' },
                { text: this.$t('management.organisation'), value: 'organisation' },
                { text: this.$t('users.birth_year'), value: 'birth_year' },
                { text: this.$t('users.gender'), value: 'gender' },
                { text: this.$t('reports.assisting_activities'), value: 'assisting_activities' },
                { text: this.$t('reports.producing_activities'), value: 'producing_activities' },
                { text: this.$t('other.total'), value: 'total' },
            ];
        },

        table_footer() {
            return {
                'items-per-page-text': this.$t('components.rows_per_page'),
                'items-per-page-all-text': this.$t('components.all'),
                //'page-text': this.$t('components.of'), // nadomesti vse s tem, ne da se spremenit samo "of"
            }
        },


    },
    methods: {
        ...mapActions([

        ]),
    }
};
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";

th {
    vertical-align: top;
}
</style>
