<template>
    <div class="v-icon">
        <v-dialog v-model="dialog" max-width="600px" @click:outside="close()">
            <template v-slot:activator="{ on, attrs }">
                <v-icon dense v-bind="attrs" v-on="on" class="mr-2" :title="$t('users.edit_user')">
                    mdi-lead-pencil
                </v-icon>
            </template>

            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ $t('users.edit_user') }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field :label="$t('users.first_name')" :rules="[v => !!v]" required
                                        v-model="name"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field :label="$t('users.last_name')" :rules="[v => !!v]" required
                                        v-model="surname">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field :label="$t('users.email')" :rules="emailRules" required
                                        v-model="email"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field :label="$t('users.password')" :rules="passRules" type="password"
                                        required v-model="password">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" v-if="getUserType == 2">
                                    <v-select item-text="text" item-value="value" :items="types"
                                        :label="$t('users.type')" :rules="[v => !!v]" required v-model="initialType">
                                    </v-select>
                                </v-col>

                                <v-col cols="12" :sm="getUserType == 2 ? '6' : '12'">
                                    <v-select item-text="text" item-value="value" :items="activity"
                                        :label="$t('users.activity')" :rules="[v => !!v]" required
                                        v-model="initialActivity">
                                    </v-select>
                                </v-col>

                                <v-col cols="12">
                                    <v-alert :value="isError" color="error" dark border="top"
                                        transition="scale-transition">
                                        {{ error_text }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="close()">
                            {{ $t('buttons.cancel') }}
                        </v-btn>
                        <v-btn class="primary" :disabled="!valid" @click="validate">
                            {{ $t('buttons.save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "EditUser",
    data: function () {
        return {
            dialog: false,
            valid: true,
            error_text: "",
            isError: false,
            name: "",
            surname: "",
            email: "",
            emailRules: [
                v => !!v,
                v => /.+@.+\..+/.test(v),
            ],
            password: "",
            passRules: [
                v => (v.length == 0 || v.length >= 8) || this.$t('users.password_error'),
            ],

            types: [
                {
                    text: "naročnik",
                    value: "0"
                },
                {
                    text: "manager",
                    value: "1"
                },
                {
                    text: "administrator",
                    value: "2"
                },
            ],
            activity: [
                {
                    text: "neaktiven",
                    value: "0"
                },
                {
                    text: "aktiven",
                    value: "1"
                }
            ],
            initialType: {},
            initialActivity: {}
        }
    },

    props: {
        user_id: {
            required: true
        },
    },

    computed: {
        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },
    },
    methods: {
        ...mapActions(["editUser"]),

        close() {
            this.$emit('close');
            this.dialog = false;
        },

        send() {
            this.editUser({
                data: {
                    id: this.user_id,
                    name: this.name,
                    surname: this.surname,
                    email: this.email,
                    type: this.initialType,
                    password: this.password,
                    active: this.initialActivity,
                },
                callback: (e) => {
                    switch (e) {
                        case 'success':
                            this.close();
                            break;
                        case 'duplicate':
                            this.isError = true;
                            this.error_text = this.$t('users.email_exist')
                            break;
                        case 'error':
                            this.isError = true;
                            this.error_text = this.$t('users.edit_error')
                            break;
                    }
                }
            });
        },

        validate() {
            let isValid = this.$refs.form.validate();
            if (isValid) this.send();
        },
    },

    created() {
        let user_data = this.$store.getters.users_list_data[this.user_id];
        this.name = user_data.name;
        this.surname = user_data.surname;
        this.email = user_data.email;
        this.initialType = this.types[user_data.type].value;
        this.initialActivity = this.activity[user_data.active].value;
    }
};
</script>


<style lang="scss" scoped>
@import "../../../scss/style.scss";
</style>
