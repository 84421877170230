<template>
    <div class="main_holder home">
        <div class="content_holder">
            <div class="content">
                <div class="container page-groups">
                    <h2>{{ $t('menu.groups') }}</h2>

                    <div class="top-toolbar">
                        <!-- User Search bar -->
                        <div class="search_bar">
                            <v-text-field dense :label="$t('other.search')" append-icon="mdi-magnify" v-model="search"
                                @keydown="currentPage=1; currentPage1=1;">
                            </v-text-field>
                        </div>

                        <div class="new-group ml-4">
                            <v-btn class="secondary">
                                {{ $t('groups.button_new_group') }}
                            </v-btn>
                        </div>

                        <div class="new-drop-in">
                            <v-btn class="primary">
                                {{ $t('groups.button_new_dropin') }}
                            </v-btn>
                        </div>
                    </div>

                    <div v-show="filteredRow.length > 0">
                        <h3>{{ $t('groups.dropins') }}</h3>
                        <div class="gruop-list">
                            <v-row>
                                <v-col v-for="(card, i) in filteredRow" :key="i" cols="12" xs="12" sm="6" md="4" lg="3">
                                    <v-card>
                                        <v-img :src="card.src" class="white--text align-end"
                                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px">
                                            <v-card-title style="word-break: break-word" v-text="card.title"></v-card-title>
                                        </v-img>

                                        <v-card-actions>
                                            <v-btn icon>
                                                <v-icon>mdi-lead-pencil</v-icon>
                                            </v-btn>

                                            <v-btn icon>
                                                <v-icon>mdi-dots-hexagon</v-icon>
                                            </v-btn>

                                            <v-spacer></v-spacer>

                                            <v-btn icon>
                                                <v-icon color="primary" large>mdi-plus-circle</v-icon>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-pagination :length="maxPage" class="mt-4" v-model="currentPage"
                                v-if="maxPage > 1" @input="onPageChange">
                            </v-pagination>
                        </div>
                    </div>

                    <div class="gruop-list mt-10" v-show="filteredRow1.length > 0">
                        <h3>{{ $t('groups.groups') }}</h3>
                        <div class="gruop-list">
                            <v-row>
                                <v-col v-for="(card, i) in filteredRow1" :key="i" cols="12" xs="12" sm="6" md="4"
                                    lg="3">
                                    <v-card>
                                        <v-img :src="card.src" class="white--text align-end"
                                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px">
                                            <v-card-title style="word-break: break-word" v-text="card.title"></v-card-title>
                                        </v-img>

                                        <v-card-actions>
                                            <v-btn icon>
                                                <v-icon>mdi-eye</v-icon>
                                            </v-btn>

                                            <v-btn icon>
                                                <v-icon>mdi-lead-pencil</v-icon>
                                            </v-btn>

                                            <v-btn icon>
                                                <v-icon>mdi-dots-hexagon</v-icon>
                                            </v-btn>

                                            <v-btn icon>
                                                <v-icon>mdi-clipboard-edit-outline</v-icon>
                                            </v-btn>

                                            <v-spacer></v-spacer>

                                            <v-btn icon>
                                                <v-icon color="primary" large>mdi-plus-circle</v-icon>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-pagination :length="maxPage1" class="mt-4" v-model="currentPage1"
                                v-if="maxPage1 > 1" @input="onPageChange1">
                            </v-pagination>
                        </div>
                    </div>
                </div>
                <br><br>
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions } from "vuex";

export default {
    name: "Groups",
    components: {
    },
    data: function () {
        return {
            search: "",
            currentPage: 1,
            perPage: 8,
            maxPage: 1,
            currentPage1: 1,
            maxPage1: 1,
            cards: [
                { title: 'Spoznavanje glasbenih inštrumentov', src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD2kZ00HJIuohQZqb9dRoBKxFW-mM3xY7btA&usqp=CAU' },
                { title: 'Skupina splet', src: 'https://www.internetsociety.org/wp-content/uploads/2022/04/Internet-of-Things-IoT-1280px.jpg' },
                { title: 'Izmenjava', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg' },
                { title: 'Delavnica za otroke', src: 'https://klccollege.ca/wp-content/uploads/July-18-child-and-youth-worker-training.jpg' },
                { title: 'Karierna pot', src: 'https://www.simplilearn.com/ice9/free_resources_article_thumb/need-for-making-an-effective-professional-career-plan-ccr14-article.jpg' },
                { title: 'Tečaj nemškega jezika', src: 'https://cdn01.alison-static.net/courses/1802/alison_courseware_intro_1802.jpg' },
                { title: 'Napredni tečaj angleškega jezika', src: 'https://excelenglishinstitute.com/wp-content/uploads/2021/03/tips-overcoming-challenges-learning-english-1200x628-1.jpg' },
                { title: 'Športne aktivnosti', src: 'https://www.beachvillas.com/travel/wp-content/uploads/2020/11/Return_of_Sports.jpg' },
                { title: 'V naravi', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg' },
                { title: 'Izlet po Goričkem', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg' },
                { title: 'Spoznavanje glasbenih inštrumentov', src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD2kZ00HJIuohQZqb9dRoBKxFW-mM3xY7btA&usqp=CAU' },
                { title: 'Skupina splet', src: 'https://www.internetsociety.org/wp-content/uploads/2022/04/Internet-of-Things-IoT-1280px.jpg' },
                { title: 'Izmenjava', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg' },
                { title: 'Delavnica za otroke', src: 'https://klccollege.ca/wp-content/uploads/July-18-child-and-youth-worker-training.jpg' },
                { title: 'Karierna pot', src: 'https://www.simplilearn.com/ice9/free_resources_article_thumb/need-for-making-an-effective-professional-career-plan-ccr14-article.jpg' },
                { title: 'Tečaj nemškega jezika', src: 'https://cdn01.alison-static.net/courses/1802/alison_courseware_intro_1802.jpg' },
                { title: 'Napredni tečaj angleškega jezika', src: 'https://excelenglishinstitute.com/wp-content/uploads/2021/03/tips-overcoming-challenges-learning-english-1200x628-1.jpg' },
                { title: 'Športne aktivnosti', src: 'https://www.beachvillas.com/travel/wp-content/uploads/2020/11/Return_of_Sports.jpg' },
                { title: 'V naravi', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg' },
                { title: 'Izlet po Goričkem', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg' },                { title: 'Spoznavanje glasbenih inštrumentov', src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD2kZ00HJIuohQZqb9dRoBKxFW-mM3xY7btA&usqp=CAU' },
                { title: 'Skupina splet', src: 'https://www.internetsociety.org/wp-content/uploads/2022/04/Internet-of-Things-IoT-1280px.jpg' },
                { title: 'Izmenjava', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg' },
                { title: 'Delavnica za otroke', src: 'https://klccollege.ca/wp-content/uploads/July-18-child-and-youth-worker-training.jpg' },
                { title: 'Karierna pot', src: 'https://www.simplilearn.com/ice9/free_resources_article_thumb/need-for-making-an-effective-professional-career-plan-ccr14-article.jpg' },
                { title: 'Tečaj nemškega jezika', src: 'https://cdn01.alison-static.net/courses/1802/alison_courseware_intro_1802.jpg' },
                { title: 'Napredni tečaj angleškega jezika', src: 'https://excelenglishinstitute.com/wp-content/uploads/2021/03/tips-overcoming-challenges-learning-english-1200x628-1.jpg' },
                { title: 'Športne aktivnosti', src: 'https://www.beachvillas.com/travel/wp-content/uploads/2020/11/Return_of_Sports.jpg' },
                { title: 'V naravi', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg' },
                { title: 'Izlet po Goričkem', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg' },                { title: 'Spoznavanje glasbenih inštrumentov', src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD2kZ00HJIuohQZqb9dRoBKxFW-mM3xY7btA&usqp=CAU' },
                { title: 'Skupina splet', src: 'https://www.internetsociety.org/wp-content/uploads/2022/04/Internet-of-Things-IoT-1280px.jpg' },
                { title: 'Izmenjava', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg' },
                { title: 'Delavnica za otroke', src: 'https://klccollege.ca/wp-content/uploads/July-18-child-and-youth-worker-training.jpg' },
                { title: 'Karierna pot', src: 'https://www.simplilearn.com/ice9/free_resources_article_thumb/need-for-making-an-effective-professional-career-plan-ccr14-article.jpg' },
                { title: 'Tečaj nemškega jezika', src: 'https://cdn01.alison-static.net/courses/1802/alison_courseware_intro_1802.jpg' },
                { title: 'Napredni tečaj angleškega jezika', src: 'https://excelenglishinstitute.com/wp-content/uploads/2021/03/tips-overcoming-challenges-learning-english-1200x628-1.jpg' },
                { title: 'Športne aktivnosti', src: 'https://www.beachvillas.com/travel/wp-content/uploads/2020/11/Return_of_Sports.jpg' },
                { title: 'V naravi', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg' },
                { title: 'Izlet po Goričkem', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg' },

            ],
            cards1: [
                { title: 'Taborjenje', src: 'https://imageio.forbes.com/specials-images/imageserve/626b045aebac0cb0f35ca2db/Tourists-sitting-near-campfire-under-starry-sky-/960x0.jpg?format=jpg&width=960' },
                { title: 'Zimovanje', src: 'https://hips.hearstapps.com/hmg-prod/images/first-day-of-winter-solstice-2021-1638278570.jpg' },
            ],
        };
    },
    computed: {

        filteredRow: function () {
            let ind = 0;
            let res = this.cards.filter((item) => {
                ind++;
                for (var key in item) {
                    //item[key] += ind;
                    if (key == "title" && String(item[key]).toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
                        return true;
                    }
                }
                return false;
            });

            //Calculate number of all pages
            this.maxPage = Math.ceil(res.length / this.perPage);
            //Return only items on given page
            return res.slice(
                this.currentPage * this.perPage - this.perPage,
                this.currentPage * this.perPage
            );
        },

        filteredRow1: function () {
            let res = this.cards1.filter((item) => {
                for (var key in item) {
                    if (key == "title" && String(item[key]).toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
                        return true;
                    }
                }
                return false;
            });

            //Calculate number of all pages
            this.maxPage1 = Math.ceil(res.length / this.perPage);
            //Return only items on given page
            return res.slice(
                this.currentPage1 * this.perPage - this.perPage,
                this.currentPage1 * this.perPage
            );
        },

    },
    methods: {
        ...mapActions([

        ]),

        onPageChange(e) {
            this.currentPage = e;
        },
        onPageChange1(e) {
            this.currentPage1 = e;
        }

    },
    created() {

    },
};
</script>


<style lang="scss" scoped>
@import "../scss/style.scss";
</style>
