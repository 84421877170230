<template>
    <div class="main_holder login">

        <div class="content_holder">

            <div class="login_box">

                <v-card class="pa-5">
                    <!-- <v-card-title>
                        <span class="text-h5">{{ $t('login.login_title') }}</span>
                    </v-card-title> -->
                    <v-card-text>
                        <div>
                            <v-text-field :label="$t('login.email')" v-model="email" type="email" ref="email">
                            </v-text-field>
                            <v-text-field :label="$t('login.password')" v-model="password"
                                @keyup.enter="loginUser({email: email, password: password})" type="password"
                                ref="password">
                            </v-text-field>
                        </div>

                        <div class="input_holder button_holder">
                            <v-btn class="primary" @click="login()">
                                {{ $t('login.login_button') }}
                            </v-btn>
                        </div>

                        <v-alert class="mt-5" :value="isError" color="error" dark border="top"
                            transition="scale-transition">
                            {{ error_text }}
                        </v-alert>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions } from 'vuex';

export default {
    name: 'Login',

    data: function () {
        return {
            email: '',
            password: '',
            error_text: '',
            isError: false
        }
    },
    methods: {
        ...mapActions([
            'loginUser'
        ]),

        login() {
            this.loginUser({
                data: { email: this.email, password: this.password },
                callback: (error) => {
                    switch (error) {
                        case 'email':
                            this.error_text = this.$t('login.warning_email')
                            break;
                        case 'not_active':
                            this.error_text = this.$t('login.warning_active')
                            break; 
                        case 'password':
                            this.error_text = this.$t('login.warning_password')
                            break;
                        default:
                            this.error_text = error;
                    }
                    this.isError = true;
                }
            });
        }
    },
    mounted() {
        this.$refs.password.focus();
    }
}
</script>


<style lang="scss" scoped>
@import "../scss/style.scss";
</style>
