<template>
    <div class="graph">
        <!-- <VueApexCharts :data="graph_data" :layout="graph_layout" :toImageButtonOptions="config.toImageButtonOptions"></VueApexCharts> -->
        <apexchart :options="graph_options" :series="graph_series"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
    name: "Graph",
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        graph_options: {
            type: Object,
            required: true,
        },
        graph_series: {
            type: Array,
            required: true,
        },
        /* type: {
            type: String,
            required: true,
        }, */
    },
    data: function () {
        return {
            /* config: {
                toImageButtonOptions: {
                    format: 'svg', // one of png, svg, jpeg, webp
                    filename: this.graph_layout.title,
                    height: 700,
                    width: 1400,
                    scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
                }
            } */
        };
    },
    computed: {
    },
    methods: {
    },
    created() {
    },
};
</script>

<style lang="scss" scoped>
@import "../scss/style.scss";
</style>
