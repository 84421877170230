<template>
    <v-tab-item>
        <div class="reports_table_toolbar mb-1">
            <!-- User Search bar -->
            <div class="search_bar">
                <v-text-field hide-details append-icon="mdi-magnify" dense :label="$t('other.search')" v-model="search">
                </v-text-field>
            </div>
        </div>

        <v-data-table :headers="headers" :search="search" :items="activities" sort-by="date"
            :footer-props="table_footer" :loading-text="this.$t('other.loading_long')">
        </v-data-table>
    </v-tab-item>
</template>


<script>
import { mapActions } from "vuex";

export default {
    name: "DataTableTab",
    components: {
    },
    data: function () {
        return {
            loading: true,
            search: "",
            activities: [
                {
                    id: 0,
                    title: 'Skupina',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    organisation: 'CONA Šmartno',
                    female: '6',
                    male: '12',
                    nonbinary: '0',
                    participants: '18',
                    producing_females: '0',
                    producing_males: '0',
                    producing_nonbinary: '0',
                    assisting_females: '0',
                    assisting_males: '0',
                    assisting_nonbinary: '0',
                    hours: '0.5',
                    youth_hours: '0',
                    staff: '3',
                },
                {
                    id: 1,
                    title: 'Ustvarjalna delavnica',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    organisation: 'CONA Šmartno',
                    female: '10',
                    male: '11',
                    nonbinary: '1',
                    participants: '22',
                    producing_females: '0',
                    producing_males: '0',
                    producing_nonbinary: '0',
                    assisting_females: '0',
                    assisting_males: '0',
                    assisting_nonbinary: '0',
                    hours: '2',
                    youth_hours: '1',
                    staff: '4',
                },
                {
                    id: 2,
                    title: 'Učna pomoč',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    organisation: 'CONA Šmartno',
                    female: '5',
                    male: '3',
                    nonbinary: '0',
                    participants: '8',
                    producing_females: '1',
                    producing_males: '0',
                    producing_nonbinary: '0',
                    assisting_females: '0',
                    assisting_males: '0',
                    assisting_nonbinary: '0',
                    hours: '1',
                    youth_hours: '0',
                    staff: '2',
                },
                {
                    id: 3,
                    title: 'Kuharska delavnica',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    organisation: 'CONA Šmartno',
                    female: '2',
                    male: '7',
                    nonbinary: '0',
                    participants: '9',
                    producing_females: '0',
                    producing_males: '0',
                    producing_nonbinary: '0',
                    assisting_females: '0',
                    assisting_males: '1',
                    assisting_nonbinary: '0',
                    hours: '2.5',
                    youth_hours: '0.5',
                    staff: '4',
                },
                {
                    id: 4,
                    title: 'Pogovor',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    organisation: 'CONA Šmartno',
                    female: '9',
                    male: '11',
                    nonbinary: '1',
                    participants: '21',
                    producing_females: '1',
                    producing_males: '2',
                    producing_nonbinary: '0',
                    assisting_females: '0',
                    assisting_males: '3',
                    assisting_nonbinary: '0',
                    hours: '2.5',
                    youth_hours: '1.5',
                    staff: '4.5',
                },
            ],
        };
    },
    computed: {
        headers() {
            return [
                { text: this.$t('other.date'), value: 'date', align: 'start' },
                { text: this.$t('other.title'), value: 'title', align: 'start' },
                { text: this.$t('groups.group'), value: 'group', align: 'start' },
                { text: this.$t('management.organisation'), value: 'organisation', align: 'start' },
                { text: this.$t('users.female'), value: 'female', align: 'start' },
                { text: this.$t('users.male'), value: 'male', align: 'start' },
                { text: this.$t('users.nonbinary'), value: 'nonbinary', align: 'start' },
                { text: this.$t('management.participants'), value: 'participants', align: 'start' },
                { text: this.$t('reports.table_tab.producing_females'), value: 'producing_females', align: 'start' },
                { text: this.$t('reports.table_tab.producing_males'), value: 'producing_males', align: 'start' },
                { text: this.$t('reports.table_tab.producing_nonbinary'), value: 'producing_nonbinary', align: 'start' },
                { text: this.$t('reports.table_tab.assisting_females'), value: 'assisting_females', align: 'start' },
                { text: this.$t('reports.table_tab.assisting_males'), value: 'assisting_males', align: 'start' },
                { text: this.$t('reports.table_tab.assisting_nonbinary'), value: 'assisting_nonbinary', align: 'start' },
                { text: this.$t('reports.charts_tab.hours'), value: 'hours', align: 'start' },
                { text: this.$t('reports.table_tab.youth_hours'), value: 'youth_hours', align: 'start' },
                { text: this.$t('management.staff'), value: 'staff', align: 'start' },
            ];
        },

        table_footer() {
            return {
                'items-per-page-text': this.$t('components.rows_per_page'),
                'items-per-page-all-text': this.$t('components.all'),
                //'page-text': this.$t('components.of'), // nadomesti vse s tem, ne da se spremenit samo "of"
            }
        },


    },
    methods: {
        ...mapActions([

        ]),
    }
};
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";
</style>
