<template>
    <v-tab-item>
        <div class="reports_table_toolbar mb-1">
            <!-- User Search bar -->
            <div class="search_bar">
                <v-text-field hide-details append-icon="mdi-magnify" dense :label="$t('other.search')" v-model="search">
                </v-text-field>
            </div>
        </div>

        <v-data-table :headers="headers" :search="search" :items="activities" sort-by="date"
            :footer-props="table_footer" :loading-text="this.$t('other.loading_long')">
        </v-data-table>
    </v-tab-item>
</template>


<script>
import { mapActions } from "vuex";

export default {
    name: "FacActTab",
    components: {
    },
    data: function () {
        return {
            loading: true,
            search: "",
            activities: [

            ],
        };
    },
    computed: {
        headers() {
            return [
                { text: this.$t('other.date'), value: 'date' },
                { text: this.$t('groups.group'), value: 'group' },
                { text: this.$t('management.organisation'), value: 'organisation' },
                { text: this.$t('reports.facilitated_activity'), value: 'facilitated_activity' },
            ];
        },

        table_footer() {
            return {
                'items-per-page-text': this.$t('components.rows_per_page'),
                'items-per-page-all-text': this.$t('components.all'),
                //'page-text': this.$t('components.of'), // nadomesti vse s tem, ne da se spremenit samo "of"
            }
        },


    },
    methods: {
        ...mapActions([

        ]),
    }
};
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";

th {
    vertical-align: top;
}
</style>
