import axios from 'axios';
import router from '../router';
import * as general_state from '../store/store';
import * as general_mutations from '../store/mutations';


export const showModalWorking = ({commit}) => {
	commit('showModalWorking');
};

export const hideModalWorking = ({commit}) => {
	commit('hideModalWorking');
};


// Login user
export const loginUser = ({commit, dispatch}, payload) => {
    axios.post('?module=user&action=login_user', payload.data)
        .then(
            res => {
                // Login succesful
                if(res.data.success === true){

                    // Commit changes to store (user, faculty, token...)
                    commit('setAuthData', res);
                    commit('setToken', res.data.token);

                    // Save token, faculty_id and user id to local storage
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('user_id', res.data.user.id);
                    let expirationDate = new Date(new Date().getTime() + general_state.state.expirationTime).getTime();
                    localStorage.setItem('expirationDate', expirationDate);

                    router.replace('/');
                }
                // Login failed
                else{
                    payload.callback(res.data.error);
                }
            }
        )
        .catch(error => console.log(error))		
};

// Logout user
export const logoutUser = ({commit}) => {

    // Commit changes to store (clear user, faculty, token...)
    commit('clearAuthData');

    // Redirect
    //router.replace('/login/');
    // Better to call refresh so all variables are set on default
    location.reload(); 
};


// Get version from db
export const prepareVersion = ({commit}, payload) => {
    axios.get('?module=other&action=get_version')
			.then(
				res => {
					if(!res.status == 200){				
						console.error('module=other&action=get_version => ' + res);
					}
					else{
						commit('prepareVersion', res.data);
					}
				}
			)
			.catch(error => console.log(error))	
};