<template>
    <div class="v-icon">
        <v-dialog v-model="dialog" max-width="600px" @click:outside="close()">
            <template v-slot:activator="{ on, attrs }">
                <v-icon dense v-bind="attrs" v-on="on" :title="$tc('users.delete_user', 1)">
                    mdi-trash-can
                </v-icon>
            </template>

            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ this.$tc('users.delete_user', 1) }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <p>{{ this.$tc('users.delete_user_content', 1) }}</p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="close()">
                        {{ $t('buttons.cancel') }}
                    </v-btn>
                    <v-btn class="primary" @click="send()">
                        {{ $t('buttons.confirm') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
import { mapActions } from "vuex";

export default {
    name: "DeleteUser",
    data: function () {
        return {
            dialog: false,
        };
    },

    props: {
        user_id: {
            required: true
        },
    },

    computed: {
    },

    methods: {
        ...mapActions(["deleteUser"]),

        close() {
            this.dialog = false;
        },

        send() {
            this.deleteUser({ id: this.user_id });
            this.close();
        },
    }
};
</script>


<style lang="scss" scoped>
@import "../../../scss/style.scss";
</style>
