let supportedLocales = {
    en: { name: "English", acr: "ENG" },
    sl: { name: "Slovenščina", acr: "SLO" },
};

//for select
/* export function getSupportedLocales() {
    let annotatedLocales = [];
    for (const code of Object.keys(supportedLocales)) {
        annotatedLocales.push({
            code,
            name: supportedLocales[code].name,
            acronym: supportedLocales[code].acr,
        });
    }
    return annotatedLocales;
} */

export function getSupportedLocales() {
    let annotatedLocales = {};
    for (const code of Object.keys(supportedLocales)) {
        annotatedLocales[code] = {
            code,
            name: supportedLocales[code].name,
            acronym: supportedLocales[code].acr,
        };
    }
    return annotatedLocales;
}

export function supportedLocalesInclude(locale) {
    return Object.keys(supportedLocales).includes(locale)
}