<template>
    <v-tab-item>
        <div class="reports_table_toolbar mb-1">
            <!-- User Search bar -->
            <div class="search_bar">
                <v-text-field hide-details append-icon="mdi-magnify" dense :label="$t('other.search')" v-model="search">
                </v-text-field>
            </div>
        </div>

        <v-data-table :headers="headers" :search="search" :items="activities" sort-by="date"
            :footer-props="table_footer" :loading-text="this.$t('other.loading_long')">
        </v-data-table>
    </v-tab-item>
</template>


<script>
import { mapActions } from "vuex";

export default {
    name: "VolunteersTab",
    components: {
    },
    data: function () {
        return {
            loading: true,
            search: "",
            activities: [
            {
                    "name": "Mihelič Teja",
                    "organisation": "CONA Šmartno",
                    "birth_year": 1992,
                    "gender": "Female",
                    "assisting_activities": 0,
                    "producing_activities": 21,
                    "total": 21
                },
                {
                    "name": "Fras Marijan",
                    "organisation": "CONA Šmartno",
                    "birth_year": 2002,
                    "gender": "Male",
                    "assisting_activities": 1,
                    "producing_activities": 2,
                    "total": 3
                },
                {
                    "name": "Kokalj Viktor",
                    "organisation": "CONA Šmartno",
                    "birth_year": 1995,
                    "gender": "Male",
                    "assisting_activities": 1,
                    "producing_activities": 62,
                    "total": 64
                },
                {
                    "name": "Lešnik Rudolf",
                    "organisation": "CONA Šmartno",
                    "birth_year": 2000,
                    "gender": "Male",
                    "assisting_activities": 0,
                    "producing_activities": 9,
                    "total": 10
                },
                {
                    "name": "Bezjak Nevenka",
                    "organisation": "CONA Šmartno",
                    "birth_year": 1980,
                    "gender": "Female",
                    "assisting_activities": 0,
                    "producing_activities": 4,
                    "total": 4
                },
            ],
        };
    },
    computed: {
        headers() {
            return [
                { text: this.$t('users.first_name'), value: 'name' },
                { text: this.$t('management.organisation'), value: 'organisation' },
                { text: this.$t('users.birth_year'), value: 'birth_year' },
                { text: this.$t('users.gender'), value: 'gender' },
                { text: this.$t('reports.assisting_activities'), value: 'assisting_activities' },
                { text: this.$t('reports.producing_activities'), value: 'producing_activities' },
                { text: this.$t('other.total'), value: 'total' },
            ];
        },

        table_footer() {
            return {
                'items-per-page-text': this.$t('components.rows_per_page'),
                'items-per-page-all-text': this.$t('components.all'),
                //'page-text': this.$t('components.of'), // nadomesti vse s tem, ne da se spremenit samo "of"
            }
        },


    },
    methods: {
        ...mapActions([

        ]),
    }
};
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";
</style>
