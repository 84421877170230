import Vue from "vue";
import VueRouter from "vue-router";
import Groups from "../views/Groups.vue";
import Activities from "../views/Activities.vue";
import Reports from "../views/Reports.vue";
import Documents from "../views/Documents.vue";
import Surveys from "../views/Surveys.vue";
import Messages from "../views/Messages.vue";
import Login from "../views/Login.vue";
import Management from "../views/Management.vue";
import UserProfile from "../views/UserProfile.vue";
import Root from "./Root"
import i18n, { loadLocaleMessagesAsync } from '../i18n';

Vue.use(VueRouter);

const { locale } = i18n;

const routes = [
    {
        path: "/",
        redirect: locale
    },
    {
        path: "/:locale",
        component: Root,
        children: [
            { path: "", name: "groups", component: Groups },
            { path: "activities", name: "activities", component: Activities },
            { path: "reports", name: "reports", component: Reports },
            { path: "documents", name: "documents", component: Documents },
            { path: "surveys", name: "surveys", component: Surveys },
            { path: "messages", name: "messages", component: Messages },
            { path: 'login', name: 'login', component: Login },
            { path: 'management', name: 'management', component: Management },
            { path: 'my-account', name: 'UserProfile', component: UserProfile },
        ]
    }





    /* {
        path: '/:locale',
        component: {
            render(c) { return c('router-view') }
            /* template: "<router-view></router-view>",
            beforeEnter: (to, from, next) => {
                const locale = to.params.locale;
                const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');
                if (!supported_locales.includes(locale)) return next('sl');
                if (i18n.locale !== locale) {
                    i18n.locale = locale;
                }
                return next()
            } */
    /*},
    children: [
        { path: "/", name: "home", component: Home },
        { path: '/login', name: 'login', component: Login },
        { path: '/users', name: 'users', component: Users },
        { path: '/my-account', name: 'UserProfile', component: UserProfile },

        {
            path: '*',
            redirect() {
                //return PerformanceObserverEntryList.env.VUE_APP_I18N_LOCALE;
            }
        }
    ]
} */
];

const router = new VueRouter({
    routes: routes,
    mode: 'history',
    base: process.env.BASE_URL,
});

router.beforeEach((to, from, next) => {
    if (to.params.locale === from.params.locale) {
        next()
        return
    }

    const { locale } = to.params
    loadLocaleMessagesAsync(locale);
    next()
})

export default router;