<template>
	<div>
		<!-- Using hide-overlay below allows for clicking while progress showing -->
		<v-dialog v-model="visible" persistent :width="options.width" v-bind:style="{ zIndex: options.zIndex }">
			<v-card :color="options.color" dark>
				<v-card-text>
					{{ $t('other.loading_long') }}
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>


<script>

export default {
	name: 'ModalWorking',
	data: () => ({
		options: {
			color: 'primary',
			width: 300,
			zIndex: 200
		},
	}),
	methods: {
	},
	computed: {
		visible() {
			return this.$store.getters.getModalWorkingVisible;
		}
	},
}
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";
</style>
