import Vue from 'vue';
//import './plugins/axios';
import App from './App.vue';
import router from './router';
import { store } from './store/store'
import vuetify from './plugins/vuetify';
import axios from 'axios';
import i18n from './i18n';
import getBrowserLocale from "@/locales/get-browser-locale";

// Axios for backend api connection
axios.defaults.baseURL = 'https://app.survey360.eu/backend/api.php';
//axios.defaults.baseURL = 'http://localhost/backend/api.php';

const browserLocale = getBrowserLocale({ countryCodeOnly: true });

Vue.config.productionTip = false;

// Axios interceptor for adding token to api requests
axios.interceptors.request.use(function (config) {
    
    var token = store.getters.getToken;
    config.headers.Authorization =  token;

    return config;
});

// Get token and user_id from local storage
var token = localStorage.getItem('token');
var user_id = localStorage.getItem('user_id');
var expirationDate = localStorage.getItem('expirationDate');

// If token and user_id is not set - redirect to login
if (!token || !user_id || !expirationDate) {    

    router.push('/'+browserLocale+'/login/');

    // Init vue
    new Vue({
        el: '#app',
        router,
        store,
        vuetify,
        i18n,
        render: h => h(App)
    })
}
// Check if login has expired
else if(new Date().getTime() >= expirationDate){
    //Auto logout
    store.dispatch('logoutUser');
}
// Token and user_id in local storage - try to auto login
else{

    // Save token to store
    store.commit('setToken', token);
        
    // Check if user logged in with valid token and get user data
    axios.get('?module=user&action=auto_login_user&user_id='+user_id)
        .then(
            res => {

                // Autologin succesful
                if(res.data.success === true){
                    store.commit('setAuthData', res);
                }
                // Login failed - clear storage and redirect to login page
                else{
                    localStorage.removeItem('token');
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('expirationDate');

                    router.push('/'+browserLocale+'/login/');
                }

                // Init vue
                new Vue({
                    el: '#app',
                    router,
                    store,
                    vuetify,
                    i18n,
                    render: h => h(App)
                })

                if(router.currentRoute.name == 'login' && res.data.success === true){
                    router.push('/');
                }
            }
        )
        .catch(error => console.log(error))
}
