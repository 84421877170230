<template>
  <v-btn class="locale-switcher primary" elevation="0" @click="toggleLocale()">
    <v-icon dense class="mr-1">mdi-web</v-icon>
    {{locales[$i18n.locale].acronym}}
    <!-- <v-select dense hide-details v-model="$i18n.locale" 
          item-text="name" item-value="code" :items="locales" 
          @change.prevent="changeLocale">
    </v-select> -->
  </v-btn>
</template>
  
<script>
import { getSupportedLocales } from "@/locales/supported-locales"

export default {
  data: () => ({ locales: getSupportedLocales() }),
  methods: {
    changeLocale(e) {
      const locale = e.target.value;

      this.$router.push({ params: { locale: locale } })
    },

    toggleLocale() {
      this.$router.push({ params: { locale: (this.$i18n.locale == 'sl' ? 'en' : 'sl') } })
    },
  }
}
</script>