<template>
    <v-card class="my-5">
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="6" lg="3" class="mt-auto">
                    <v-menu ref="datemenu" v-model="datemenu" :close-on-content-click="false" :return-value.sync="dates"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined v-model="dateRangeText" :label="this_.$t('activities.date_range')"
                                prepend-inner-icon="mdi-calendar" hide-details readonly v-bind="attrs" v-on="on" @click:prepend-inner="on.click">
                            </v-text-field>
                        </template>
                        <v-date-picker range v-model="dates" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="datemenu = false">
                                {{ $t('buttons.cancel') }}
                            </v-btn>
                            <v-btn text color="primary" @click="$refs.datemenu.save(dates)">
                                {{ $t('buttons.ok') }}
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="6" lg="3" class="mt-auto">
                    <v-combobox outlined v-model="chipsWeekdays" :items="itemsWeekdays" small-chips clearable
                        :label="this.$t('reports.weekdays')" hide-details multiple>
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                @click:close="removeChip(item, chipsWeekdays)"> {{ item }}
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-col>
                <v-col cols="12" sm="6" lg="3" class="mt-auto">
                    <v-select  outlined v-model="selectFilter" :items="itemsFilter" height="42" :label="this.$t('other.filter')"
                        hide-details>
                    </v-select>
                </v-col>
                <v-col cols="12" sm="6" lg="3" class="mt-auto">
                    <v-text-field outlined append-icon="mdi-magnify" hide-details :label="$t('other.search')"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" lg="4" class="mt-auto">
                    <v-combobox outlined v-model="chipsOrg" :items="itemsOrg" small-chips clearable
                        :label="this.$t('management.organisations')" hide-details multiple>
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                @click:close="removeChip(item, chipsOrg)"> {{ item }}
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-col>
                <v-col cols="12" sm="6" lg="4" class="mt-auto">
                    <v-combobox outlined v-model="chipsGroups" :items="itemsGroups" small-chips clearable
                        :label="this.$t('groups.groups')" hide-details multiple>
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                @click:close="removeChip(item, chipsGroups)"> {{ item }}
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-col>
                <v-col cols="12" sm="6" lg="4" class="mt-auto">
                    <v-combobox  outlined v-model="chipsDropins" :items="itemsDropins" small-chips clearable
                        :label="this.$t('groups.dropins')" hide-details multiple>
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                @click:close="removeChip(item, chipsDropins)"> {{ item }}
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-col>
                <v-col cols="12" sm="6" lg="4" class="mt-auto">
                    <v-combobox outlined v-model="chipsGTypes" hide-details :items="itemsGTypes" small-chips clearable
                        :label="this.$t('activities.group_types')" multiple>
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                @click:close="removeChip(item, chipsGTypes)"> {{ item }}
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-col>
                <v-col cols="12" sm="6" lg="4" class="mt-auto">
                    <v-combobox outlined v-model="chipsTargetG" hide-details :items="itemsTargetG" small-chips clearable
                        :label="this.$t('management.target_groups')" multiple>
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                @click:close="removeChip(item, TargetG)"> {{ item }}
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-col>
                <v-col cols="12" sm="6" lg="4" class="mt-auto">
                    <v-combobox outlined v-model="chipsATypes" hide-details :items="itemsATypes" small-chips clearable
                        :label="this.$t('management.activity_types')" multiple>
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                @click:close="removeChip(item, chipsATypes)"> {{ item }}
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>


<script>
import { mapActions } from "vuex";

export default {
    name: "ReportsToolbar",
    components: {
    },
    data: function () {
        return {
            loading: true,
            search: "",
            selected_activities: [],
            componentKey: 0,
            dates: [],
            datemenu: false,

            activities: [
                {
                    id: 0,
                    title: 'V naravi',
                    group: 'skupina 343',
                    date: '22.11.2022',
                    creator: 'Mojca Krapnik',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 1,
                    title: 'Izmenjava',
                    group: 'skupina 343',
                    date: '2.12.2022',
                    creator: 'Mojca Krapnik',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 2,
                    title: 'Izlet po Goričkem',
                    group: 'skupina 355',
                    date: '29.11.2022',
                    creator: 'Mojca Krapnik',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 3,
                    title: 'V naravi',
                    group: 'skupina 389',
                    date: '5.1.2023',
                    creator: 'Mojca Krapnik',
                    created: '8.8.2022',
                    important: ''
                }
            ],
            selectFilter: [],
            chipsWeekdays: [],
            chipsOrg: [],
            itemsOrg: ['Mladi Zmaji Šiškas', 'Mladi Zmaji Moste', 'Mladi Zmaji Bežigrad', 'Mladi Zmaji Center', 'Mladi Zmaji Vič'],
            chipsGroups: [],
            itemsGroups: ['Skupina 236', 'Skupina 238', 'Skupina 336', 'Skupina 256', 'Skupina 239'],
            chipsDropins: [],
            itemsDropins: ['Odprta aktivnost 236', 'Odprta aktivnost 238', 'Odprta aktivnost 336', 'Odprta aktivnost 256', 'Odprta aktivnost 239'],
            chipsGTypes: [],
            itemsGTypes: ['Individualna podpora', 'Interesna skupina', 'Kampiranje', 'Mladinski dogodek', 'Mladisnkis svet', 'Projektna skupina'],
            chipsTargetG: [],
            itemsTargetG: ['Skupina 236', 'Skupina 238', 'Skupina 336', 'Skupina 256', 'Skupina 239'],
            chipsATypes: [],
            itemsATypes: ['Drugo', 'Druženje', 'Informiranje', 'Kultura in ustvarjalnost', 'Mobilnost mladih', 'Šport', 'Zdravje', 'Učna pomoč', 'Svetovanje'],
        };
    },
    computed: {

        this_() {
            return this;
        },

        itemsFilter() {
            return [
                this.$t('menu.groups'),
                this.$t('groups.groups'),
                this.$t('groups.dropins'),
                this.$t('other.total')
            ]
        },

        itemsWeekdays() {
            return [
                this.$t('reports.monday'),
                this.$t('reports.tuesday'),
                this.$t('reports.wednesday'),
                this.$t('reports.thursday'),
                this.$t('reports.friday'),
                this.$t('reports.saturday'),
                this.$t('reports.sunday')
            ]
        },

        dateRangeText() {
            return this.dates.join(' - ')
        },

        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },
        getUserID() {
            return this.$store.getters.getUser.id;
        },
    },
    methods: {
        ...mapActions([

        ]),

        removeChip(item, chips) {
            chips.splice(chips.indexOf(item), 1)
        },

        forceRerenderDialog() {
            this.componentKey += 1;
        },
    },

    created() {
    },
};
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";
</style>
