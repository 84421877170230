<template>
    <div class="main_holder home">
        <div class="content_holder">
            <div class="content">
                <div class="container">
                    <!-- <h2>{{ $t('menu.surveys') }}</h2> -->

                    <v-tabs v-model="tab" background-color="transparent" color="primary" fixed-tabs center-active>
                        <v-tab v-for="(tab_name, i) in tabItems" :key="i">
                            {{ tab_name }}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-card>
                                <v-card-title class="text-center mb-6 mt-4">
                                    {{ $t('survey.active_survey') }}:&nbsp;<a href="https://mama.survey360.eu/individualni" target="_blank">
                                        https://mama.survey360.eu/individualni</a>
                                </v-card-title>
                            </v-card>

                            <v-card>
                                <v-tabs v-model="subtab" background-color="secondary" fixed-tabs center-active dark>
                                    <v-tab v-for="(tab_name, i) in subTabItems" :key="i">
                                        {{ tab_name }}
                                    </v-tab>
                                </v-tabs>
                                <v-card-text class="text-center pa-0">
                                    <v-tabs-items v-model="subtab">
                                        <v-tab-item>
                                            <div class="iframe">
                                                <iframe src="https://mama.survey360.eu/podatki/31/159238D9/"
                                                    style="display:block; width:100%; height:70vh;" frameborder="0">
                                                </iframe>
                                            </div>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <div class="iframe">
                                                <iframe src="https://mama.survey360.eu/podatki/31/EB13FCE6/"
                                                    style="display:block; width:100%; height:70vh;" frameborder="0">
                                                </iframe>
                                            </div>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <div class="iframe">
                                                <iframe src="https://mama.survey360.eu/podatki/31/6067B668/"
                                                    style="display:block; width:100%; height:70vh;" frameborder="0">
                                                </iframe>
                                            </div>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item>
                            <v-card>
                                <v-card-title class="text-center mb-6 mt-4">
                                    {{ $t('survey.active_survey') }}:&nbsp;<a href="https://mama.survey360.eu/ucinkovitost" target="_blank">
                                        https://mama.survey360.eu/ucinkovitost</a>
                                </v-card-title>
                            </v-card>

                            <v-card>
                                <v-tabs v-model="subtab" background-color="secondary" fixed-tabs center-active dark>
                                    <v-tab v-for="(tab_name, i) in subTabItems" :key="i">
                                        {{ tab_name }}
                                    </v-tab>
                                </v-tabs>
                                <v-card-text class="text-center pa-0">
                                    <v-tabs-items v-model="subtab">
                                        <v-tab-item>
                                            <div class="iframe">
                                                <iframe src="https://mama.survey360.eu/podatki/30/04E1C56F/"
                                                    style="display:block; width:100%; height:70vh;" frameborder="0">
                                                </iframe>
                                            </div>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <div class="iframe">
                                                <iframe src="https://mama.survey360.eu/podatki/30/E2BC9B40/"
                                                    style="display:block; width:100%; height:70vh;" frameborder="0">
                                                </iframe>
                                            </div>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <div class="iframe">
                                                <iframe src="https://mama.survey360.eu/podatki/30/DDB70827/"
                                                    style="display:block; width:100%; height:70vh;" frameborder="0">
                                                </iframe>
                                            </div>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item>
                            <v-card>
                                <v-card-title class="text-center mb-6 mt-4">
                                    {{ $t('survey.active_survey') }}:&nbsp;<a href="https://mama.survey360.eu/skupinskivp" target="_blank">
                                        https://mama.survey360.eu/skupinskivp</a>
                                </v-card-title>
                            </v-card>

                            <v-card>
                                <v-tabs v-model="subtab" background-color="secondary" fixed-tabs center-active dark>
                                    <v-tab v-for="(tab_name, i) in subTabItems" :key="i">
                                        {{ tab_name }}
                                    </v-tab>
                                </v-tabs>
                                <v-card-text class="text-center pa-0">
                                    <v-tabs-items v-model="subtab">
                                        <v-tab-item>
                                            <div class="iframe">
                                                <iframe src="https://mama.survey360.eu/podatki/32/4DD8FAAA/"
                                                    style="display:block; width:100%; height:70vh;" frameborder="0">
                                                </iframe>
                                            </div>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <div class="iframe">
                                                <iframe src="https://mama.survey360.eu/podatki/32/AE860C66/"
                                                    style="display:block; width:100%; height:70vh;" frameborder="0">
                                                </iframe>
                                            </div>
                                        </v-tab-item>
                                        <v-tab-item>
                                            <div class="iframe">
                                                <iframe src="https://mama.survey360.eu/podatki/32/815E51A2/"
                                                    style="display:block; width:100%; height:70vh;" frameborder="0">
                                                </iframe>
                                            </div>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>


                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions } from "vuex";

export default {
    name: "Surveys",
    components: {
    },
    data: function () {
        return {
            tab: null,
            subtab: null
        };
    },
    computed: {
        subTabItems() {
            return [
                this.$t('survey.frequencies'),
                this.$t('survey.summary'),
                this.$t('survey.charts'),
            ]
        },

        tabItems() {
            return [
                this.$t('survey.s_individual'),
                this.$t('survey.s_efficiency'),
                this.$t('survey.s_group'),
            ]
        },
    },
    methods: {
        ...mapActions([

        ]),

        resizeIframe(event) {
            //onload="console.log(this.contentWindow.document);this.style.height='500px'" scrolling="no"
            //@onload="resizeIframe($event)" scrolling="no"
            console.log(event);
            event.style.height = event.contentWindow.document.documentElement.scrollHeight + 'px';
        }

    },
    created() {

    },
};
</script>


<style lang="scss" scoped>
@import "../scss/style.scss";
</style>
