<template>
    <v-tab-item>
        <div class="mb-5">
            <v-row>
                <v-col cols="6" sm="6" lg="3">
                    <v-card class="my-1 ml-2" color="accent">
                        <v-card-text>
                            <div class="text-h5 font-weight-regular white--text">{{
                                    this.$t('reports.charts_tab.activities')
                            }}</div>
                            <div class="text-h2 my-7 white--text">120</div>
                            <div class="text-body-2 white--text text-no-wrap">{{
                                    this.$t('reports.charts_tab.activities_num')
                            }}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="6" sm="6" lg="3">
                    <v-card class="my-1" color="primary lighten-1">
                        <v-card-text>
                            <div class="text-h5 mb-9 font-weight-regular white--text">{{
                                    this.$t('reports.charts_tab.hours')
                            }}
                            </div>
                            <div class="text-h4 mb-10 font-weight-regular white--text">314h 0m</div>
                            <div class="text-body-2 white--text text-no-wrap">{{ this.$t('reports.charts_tab.hours_num')
                            }}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="6" sm="6" lg="3">
                    <v-card class="my-1" color="secondary darken-1">
                        <v-card-text>
                            <div class="text-h6 font-weight-regular white--text">{{ this.$t('management.participants')
                            }}
                            </div>
                            <div class="text-h5 mb-2 font-weight-regular white--text">372</div>
                            <div class="text-h6 font-weight-regular white--text">{{ this.$t('management.volunteers') }}
                            </div>
                            <div class="text-h5 mb-3 font-weight-regular white--text">2</div>
                            <div class="text-body-2 white--text text-no-wrap">{{ this.$t('reports.charts_tab.visits') }}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="6" sm="6" lg="3">
                    <v-card class="my-1 mr-2" color="accent lighten-1">
                        <v-card-text>
                            <div class="text-h5 mb-6 font-weight-regular white--text">{{
                                    this.$t('reports.charts_tab.facilitation')
                            }}</div>
                            <div class="text-h5 font-weight-regular white--text">58h 0m</div>
                            <div class="text-h5 mb-7 font-weight-regular white--text" white--text>(18%)</div>
                            <div class="text-body-2 white--text text-no-wrap">{{
                                    this.$t('reports.charts_tab.facilitation_hours')
                            }}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div class="charts">
            <v-row>
                <v-col cols="12" md="7">
                    <Graph :graph_options="bar_graph_options" :graph_series="bar_graph_series"></Graph>
                </v-col>
                <v-col cols="12" md="5">
                    <Graph :graph_options="pie_graph_options" :graph_series="pie_graph_series"></Graph>
                </v-col>
            </v-row>
        </div>
    </v-tab-item>
</template>


<script>
import { mapActions } from "vuex";
import Graph from "../Graph.vue";

export default {
    name: "ChartsTab",
    components: {
        Graph
    },
    data: function () {
        return {
            loading: true,
            selected_activities: [],
            componentKey: 0,
        };
    },
    computed: {

        pie_graph_options() {
            return {
                chart: {
                    //width: 380,
                    type: 'pie',
                },
                labels: [this.$t('users.female'), this.$t('users.male'), this.$t('users.nonbinary')],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            }
        },

        pie_graph_series() {
            return [174, 188, 16];
        },

        bar_graph_options() {
            return {
                chart: {
                    type: 'bar',
                    //height: 350,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    },
                },
                xaxis: {
                    type: 'text',
                    categories: ['Cona Šmartno (drop-ini)', 'Cona Šmartno (skupine)', 'MC Breg (dropini)', 'MC Breg (skupine)'],
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                }
            }
        },

        bar_graph_series() {
            return [{
                name: this.$t('users.female'),
                data: [61, 36, 34, 43]
            }, {
                name: this.$t('users.male'),
                data: [60, 26, 48, 54]
            }, {
                name: this.$t('users.nonbinary'),
                data: [3, 4, 3, 6]
            }];
        },

        /* bar_graph_layout() {
            return {
                title: this.$t('reports.charts_tab.participants_gender'),
                showlegend: true,
                barmode: 'stack'
                //xaxis:{
                //    type: 'category'
                //} 
            };
        },
    
        bar_graph_data() {
            var trace1 = {
                x: ['MC BOB (drop-ini)', 'MC BOB (skupine)', 'ULCA (dropini)', 'ULCA (skupine)'],
                y: [61, 36, 34, 43],
                name: this.$t('users.female'),
                type: 'bar'
            };
            var trace2 = {
                x: ['MC BOB (drop-ini)', 'MC BOB (skupine)', 'ULCA (dropini)', 'ULCA (skupine)'],
                y: [60, 26, 48, 54],
                name: this.$t('users.male'),
                type: 'bar'
            };
            var trace3 = {
                x: ['MC BOB (drop-ini)', 'MC BOB (skupine)', 'ULCA (dropini)', 'ULCA (skupine)'],
                y: [3, 4, 3, 6],
                name: this.$t('users.nonbinary'),
                type: 'bar'
            };
    
            return [trace3, trace2, trace1];
        },
    
        pie_graph_layout() {
            return {
                title: this.$t('reports.charts_tab.participants_gender_total'),
                showlegend: true,
            };
        },
    
        pie_graph_data() {
            return [{
                values: [174, 188, 16],
                labels: [this.$t('users.female'), this.$t('users.male'), this.$t('users.nonbinary')],
                type: 'pie'
            }];
        }, */



    },
    methods: {
        ...mapActions([

        ]),
    }
};
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";
</style>
