<template>
    <div class="main_holder">
        <div class="content_holder">
            <div class="content">
                <div class="container">

                    <h2>{{ $t('menu.activities') }}</h2>

                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6" lg="4" class="mt-auto">
                                    <v-menu ref="datemenu" v-model="datemenu" :close-on-content-click="false"
                                        :return-value.sync="dates" transition="scale-transition" offset-y
                                        min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="dateRangeText" outlined
                                                :label="this_.$t('activities.date_range')" prepend-inner-icon="mdi-calendar"
                                                hide-details readonly v-bind="attrs" v-on="on" @click:prepend-inner="on.click">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker range v-model="dates" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="datemenu = false">
                                                {{ $t('buttons.cancel') }}
                                            </v-btn>
                                            <v-btn text color="primary" @click="$refs.datemenu.save(dates)">
                                                {{ $t('buttons.ok') }}
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="6" lg="4" class="mt-auto">
                                    <v-combobox outlined v-model="chipsGroups" :items="itemsGroups" small-chips clearable
                                        :label="this.$t('groups.groups')" hide-details multiple>
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                                @click:close="removeChip(item, chipsGroups)"> {{ item }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="12" sm="6" lg="4" class="mt-auto">
                                    <v-combobox outlined v-model="chipsDropins" :items="itemsDropins" small-chips clearable
                                        :label="this.$t('groups.dropins')" hide-details multiple>
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                                @click:close="removeChip(item, chipsDropins)"> {{ item }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="12" sm="6" lg="4" class="mt-auto">
                                    <v-combobox outlined v-model="chipsGTypes" hide-details :items="itemsGTypes" small-chips clearable
                                        :label="this.$t('activities.group_types')" multiple>
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                                @click:close="removeChip(item, chipsGTypes)"> {{ item }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="12" sm="6" lg="4" class="mt-auto">
                                    <v-combobox outlined v-model="chipsTargetG" hide-details :items="itemsTargetG" small-chips
                                        clearable :label="this.$t('management.target_groups')" multiple>
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                                @click:close="removeChip(item, TargetG)"> {{ item }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="12" sm="6" lg="4" class="mt-auto">
                                    <v-combobox outlined v-model="chipsATypes" hide-details :items="itemsATypes" small-chips clearable
                                        :label="this.$t('management.activity_types')" multiple>
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                                @click:close="removeChip(item, chipsATypes)"> {{ item }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <div>
                        <div class="activities_toolbar">
                            <!-- User Search bar -->
                            <div class="search_bar">
                                <v-text-field append-icon="mdi-magnify" dense :label="$t('other.search')"
                                    v-model="search"></v-text-field>
                            </div>

                            <!-- Add user button -->
                            <div class="button_add_position">
                                <CheckIn :activities="selected_activities" @close="forceRerenderDialog()"
                                    :key="`${componentKey}-add`" />
                            </div>
                        </div>


                        <v-data-table :headers="headers" :search="search" :items="activities" sort-by="date"
                            class="elevation-1" :footer-props="table_footer"
                            :loading-text="this.$t('other.loading_long')">
                            <template v-slot:item.checkbox="{ item }">
                                <v-layout justify-center>
                                    <v-checkbox class="mt-0 d-flex justify-center" hide-details dense
                                        v-model="selected_activities" :value="item.id" />
                                </v-layout>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-layout justify-center>
                                    <v-icon dense class="mr-2">mdi-lead-pencil</v-icon>
                                    <v-icon dense>mdi-eye</v-icon>
                                </v-layout>
                            </template>

                        </v-data-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions } from "vuex";
import CheckIn from "../components/modal/activities/CheckIn.vue";

export default {
    name: "Activities",
    components: {
        CheckIn
    },
    data: function () {
        return {
            loading: true,
            search: "",
            selected_activities: [],
            componentKey: 0,
            dates: [],
            datemenu: false,

            activities: [
                {
                    id: 0,
                    title: 'Skupina',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 1,
                    title: 'Ustvarjalna delavnica',
                    group: 'Dnevni center',
                    date: '2.12.2022',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 2,
                    title: 'Učna pomoč',
                    group: 'Dnevni center',
                    date: '29.11.2022',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 3,
                    title: 'Kuharska delavnica',
                    group: 'Dnevni center',
                    date: '5.1.2023',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 4,
                    title: 'Skupina',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 5,
                    title: 'Pogovor',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 6,
                    title: 'Ustvarjalna delavnica',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 7,
                    title: 'Skupina',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 8,
                    title: 'Kuharska delavnica',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 9,
                    title: 'Ustvarjalna delavnica',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 10,
                    title: 'Pogovor',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 11,
                    title: 'Kuharska delavnica',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 12,
                    title: 'Učna pomoč',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 13,
                    title: 'Skupina',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 14,
                    title: 'Pogovor',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 15,
                    title: 'Učna pomoč',
                    group: 'Dnevni center',
                    date: '22.11.2022',
                    creator: 'Cona šmartno',
                    created: '8.8.2022',
                    important: ''
                },
            ],
            chipsGroups: [],
            itemsGroups: ['Skupina 236', 'Skupina 238', 'Skupina 336', 'Skupina 256', 'Skupina 239'],
            chipsDropins: [],
            itemsDropins: ['Odprta aktivnost 236', 'Odprta aktivnost 238', 'Odprta aktivnost 336', 'SkuOdprta aktivnostpina 256', 'Odprta aktivnost 239'],
            chipsGTypes: [],
            itemsGTypes: ['Individualna podpora', 'Interesna skupina', 'Kampiranje', 'Mladinski dogodek', 'Mladisnkis svet', 'Projektna skupina'],
            chipsTargetG: [],
            itemsTargetG: ['Skupina 236', 'Skupina 238', 'Skupina 336', 'Skupina 256', 'Skupina 239'],
            chipsATypes: [],
            itemsATypes: ['Drugo', 'Druženje', 'Informiranje', 'Kultura in ustvarjalnost', 'Mobilnost mladih', 'Šport', 'Zdravje', 'Učna pomoč', 'Svetovanje'],
        };
    },
    computed: {
        user_data() {
            this.selected_activities = [];

            let data = this.$store.getters.users_list_data
                ? this.$store.getters.users_list_data
                : {};

            let values = Object.values(data);
            return values;
        },

        this_(){
            return this;
        },

        dateRangeText() {
            return this.dates.join(' - ')
        },

        headers() {
            return [
                { text: this.$t('other.title'), value: 'title' },
                { text: this.$t('activities.group_drop'), value: 'group' },
                { text: this.$t('other.date'), value: 'date' },
                { text: this.$t('activities.creator'), value: 'creator' },
                { text: this.$t('other.created'), value: 'created' },
                { text: this.$t('other.important'), value: 'important' },
                { text: this.$t('other.more'), value: 'actions', sortable: false, align: 'center' },
                { text: this.$t('activities.checkin'), value: 'checkbox', sortable: false, align: 'center' },
            ];
        },

        table_footer() {
            return {
                'items-per-page-text': this.$t('components.rows_per_page'),
                'items-per-page-all-text': this.$t('components.all'),
                //'page-text': this.$t('components.of'), // nadomesti vse s tem, ne da se spremenit samo "of"
            }
        },

        filteredRow: function () {
            return this.user_data.filter((user) => {
                //Dont show admins to manager
                if (this.getUserType < 2 && user.type > 1) return false;

                for (var key in user) {
                    if (String(user[key]).indexOf(this.search) !== -1 && this.getUserID != user.id) {
                        return true;
                    }
                }
                return false;
            });
        },

        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },
        getUserID() {
            return this.$store.getters.getUser.id;
        },
    },
    methods: {
        ...mapActions([

        ]),

        removeChip(item, chips) {
            chips.splice(chips.indexOf(item), 1)
        },

        forceRerenderDialog() {
            this.componentKey += 1;
        },

        /* select() {
            this.selected_activities = [];
            for (let i in this.user_data) {
                this.userIds.push(this.user_data[i].id);
            }
        }, */
    },

    created() {
    },
};
</script>


<style lang="scss" scoped>
@import "../scss/style.scss";
</style>
