<template>
    <div class="footer_content">
        <!--<span class="author">Avtorji: Peter Hrvatin, Miha Matjašič, Uroš Podkrižnik</span>-->
        {{ $t('other.version_footer', {v: getVersion}) }}
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "Footer",
    data: function () {
        return {};
    },
    computed: {
        // Get app version
        getVersion() {
            return this.$store.getters.getVersion;
        },
    },
    methods: {
        ...mapActions(["prepareVersion"]),
    },
    created() {
        this.prepareVersion();
    },
};
</script>

<style lang="scss" scoped>
@import "../scss/style.scss";
</style>
