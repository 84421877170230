<template>
    <div class="main_holder">
        <div class="content_holder">
            <div class="content">
                <div class="my_account_form mx-auto mx-lg-0">
                    <v-card>
                        <v-card-title>
                            <h2>{{ $t('users.account_title') }}</h2>
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field :label="$t('users.first_name')" :rules="[v => !!v]" required
                                            v-model="name" maxlength="50"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field :label="$t('users.last_name')" :rules="[v => !!v]" required
                                            v-model="surname" maxlength="50">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field :label="$t('users.email')" :rules="emailRules" required
                                            maxlength="50" v-model="email"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field :label="$t('users.password')" :rules="passRules" type="password"
                                            required v-model="password" autocomplete="new-password">
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="input_holder button_holder text-left">
                                        <v-btn @click="logoutUser">
                                            <v-icon class="mr-2">mdi-logout</v-icon>
                                            {{ $t('menu.logout') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col class="input_holder button_holder text-right">
                                        <v-btn class="primary" :disabled="!valid" @click="validate">
                                            {{ $t('buttons.save') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <v-alert class="mt-5" :value="isError" color="error" dark border="top"
                                    transition="scale-transition">
                                    {{ error_text }}
                                </v-alert>
                                <v-alert class="mt-5" :value="isSuccess" color="success" dark border="top"
                                    transition="scale-transition">
                                    {{ $t('other.changes_saved') }}
                                </v-alert>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions } from "vuex";

export default {
    name: "UserProfile",
    components: {
    },
    data: function () {
        return {
            name: "",
            surname: "",
            email: "",
            password: "",
            error_text: "",
            emailRules: [
                v => !!v,
                v => /.+@.+\..+/.test(v),
            ],
            passRules: [
                v => (v.length == 0 || v.length >= 8) || this.$t('users.password_error'),
            ],
            valid: true,
            isSuccess: false,
            isError: false
        };
    },
    computed: {
        user_data() {
            return this.$store.getters.getUser;
        }
    },
    methods: {
        ...mapActions([
            "editUser",
            "logoutUser"
        ]),

        set_basic_user_data() {
            if (this.user_data.name) {
                this.name = this.$store.getters.getUser.name;
                this.surname = this.$store.getters.getUser.surname;
                this.email = this.$store.getters.getUser.email;
            }
        },

        validate() {
            let isValid = this.$refs.form.validate();
            if (isValid) this.send();
        },

        send() {
            this.isError = false;

            this.editUser({
                data: {
                    id: this.user_data.id,
                    name: this.name,
                    surname: this.surname,
                    email: this.email,
                    password: this.password,
                    type: this.user_data.type,
                    active: 1,
                },
                callback: (e) => {
                    switch (e) {
                        case 'success':
                            this.isSuccess = true;
                            setTimeout(() => { this.isSuccess = false }, 5000);
                            break;
                        case 'duplicate':
                            this.isError = true;
                            this.error_text = this.$t('users.email_exist')
                            break;
                        case 'error':
                            this.isError = true;
                            this.error_text = this.$t('users.edit_error')
                            break;
                    }
                }
            });
        },
    },
    created() {
        this.set_basic_user_data();
    },
};
</script>


<style lang="scss" scoped>
@import "../scss/style.scss";
</style>
