<template>
    <div class="main_holder">
        <div class="content_holder">
            <div class="content">
                <div class="container">

                    <!-- <h2>{{ $t('menu.reports') }}</h2> -->

                    <ReportsToolbar />

                    <v-card>
                        <v-tabs v-model="tab" background-color="secondary" fixed-tabs center-active dark>
                            <v-tab v-for="(tab_name, i) in tabItems" :key="i">
                                {{ tab_name }}
                            </v-tab>
                        </v-tabs>
                        <v-card-text class="text-center">
                            <v-tabs-items v-model="tab">
                                <ChartsTab />
                                <DataTableTab />
                                <NotesTab />
                                <FacActTab />
                                <FollowupTab />
                                <ParticipantsTab />
                                <VolunteersTab />
                            </v-tabs-items>
                        </v-card-text>
                    </v-card>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions } from "vuex";
import ReportsToolbar from "../components/reports/ReportsToolbar.vue";
import ChartsTab from "../components/reports/ChartsTab.vue";
import DataTableTab from "../components/reports/DataTableTab.vue";
import NotesTab from "../components/reports/NotesTab.vue";
import FacActTab from "../components/reports/FacActTab.vue";
import FollowupTab from "../components/reports/FollowupTab.vue";
import ParticipantsTab from "../components/reports/ParticipantsTab.vue";
import VolunteersTab from "../components/reports/VolunteersTab.vue";

export default {
    name: "Reports",
    components: {
        ReportsToolbar,
        ChartsTab,
        DataTableTab,
        NotesTab,
        FacActTab,
        FollowupTab,
        ParticipantsTab,
        VolunteersTab,
    },
    data: function () {
        return {
            tab: null,
            loading: true,
            search: "",
            dates: [],
            datemenu: false,

            activities: [
                {
                    id: 0,
                    title: 'V naravi',
                    group: 'skupina 343',
                    date: '22.11.2022',
                    creator: 'Mojca Krapnik',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 1,
                    title: 'Izmenjava',
                    group: 'skupina 343',
                    date: '2.12.2022',
                    creator: 'Mojca Krapnik',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 2,
                    title: 'Izlet po Goričkem',
                    group: 'skupina 355',
                    date: '29.11.2022',
                    creator: 'Mojca Krapnik',
                    created: '8.8.2022',
                    important: ''
                },
                {
                    id: 3,
                    title: 'V naravi',
                    group: 'skupina 389',
                    date: '5.1.2023',
                    creator: 'Mojca Krapnik',
                    created: '8.8.2022',
                    important: ''
                }
            ],
            chipsGroups: [],
            itemsGroups: ['Skupina 236', 'Skupina 238', 'Skupina 336', 'Skupina 256', 'Skupina 239'],
            chipsDropins: [],
            itemsDropins: ['Odprta aktivnost 236', 'Odprta aktivnost 238', 'Odprta aktivnost 336', 'Odprta aktivnost 256', 'Odprta aktivnost 239'],
            chipsGTypes: [],
            itemsGTypes: ['Individualna podpora', 'Interesna skupina', 'Kampiranje', 'Mladinski dogodek', 'Mladisnkis svet', 'Projektna skupina'],
            chipsTargetG: [],
            itemsTargetG: ['Skupina 236', 'Skupina 238', 'Skupina 336', 'Skupina 256', 'Skupina 239'],
            chipsATypes: [],
            itemsATypes: ['Drugo', 'Druženje', 'Informiranje', 'Kultura in ustvarjalnost', 'Mobilnost mladih', 'Šport', 'Zdravje', 'Učna pomoč', 'Svetovanje'],
        };
    },
    computed: {

        tabItems() {
            return [
                this.$t('reports.charts'),
                this.$t('reports.data_table'),
                this.$t('reports.notes'),
                this.$t('reports.facilitated_activities'),
                this.$t('reports.followup'),
                this.$t('management.participants'),
                this.$t('management.volunteers')
            ]
        },

        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },
        getUserID() {
            return this.$store.getters.getUser.id;
        },
    },
    methods: {
        ...mapActions([

        ]),

        removeChip(item, chips) {
            chips.splice(chips.indexOf(item), 1)
        },

        forceRerenderDialog() {
            this.componentKey += 1;
        },
    },

    created() {
    },
};
</script>


<style lang="scss" scoped>
@import "../scss/style.scss";
</style>
