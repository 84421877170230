export const getModalWorkingVisible = state => {
	return state.modalWorkingVisible;
};

// User getters
export const isAuthenticated = state => {
	return state.isAuthenticated;
};
export const getToken = state => {
	return state.token;
};
export const getUser = state => {
	return state.user;
};


// Version getter
export const getVersion = state => {
	return state.version;
};

